function getSpinner(options = {}) {
  let spinnerClass = '';

  if (options.small) {
    spinnerClass = ' spinner--small';
  } else if (options.tiny) {
    spinnerClass = ' spinner--15px';
  }

  if (options.dark) {
    spinnerClass += ' spinner--dark';
  }

  return `<div class='spinner${spinnerClass}'></div>`;
}

export default getSpinner;
